import React from 'react'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

export default function Transition(props) {
    const {cont,pathname,timeout=500}=props
    return (
      <TransitionGroup>
              <CSSTransition
                key={pathname}
                classNames={{
                  appear: 'animate__animated animate__fast',
                  appearActive: 'animate__fadeIn',
                  enter: 'animate__animated animate__fast',
                  enterActive: 'animate__fadeIn',
                  exit: 'animate__animated animate__fast',
                  exitActive: 'animate__fadeOut'
                }}
                timeout={timeout}
                appear={true}
                unmountOnExit
              >
                {cont}
              </CSSTransition>
    </TransitionGroup>
  )
}
