import React,{useState,useEffect} from 'react'
import { connect } from 'react-redux'
import { Swiper, SwiperSlide  } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/scrollbar';
import tStyle from './index.module.scss'

function SwiperItemDom(props) {
    const {options,platform,slidesNum,btnW,infoStyle,showStyle}=props
   
    const [state,setState]=useState(null)
    const [actionIdx,setActionIdx]=useState(0)
    useEffect(()=>{
        if(props.platform == 'phone'){
            let s_dom=document.querySelector('.swiper_box')
            s_dom.style.paddingLeft=0
            s_dom.style.paddingRight=0
        }
        resizeFun()
        window.addEventListener('resize',resizeFun)
    }, [])
    
    //监听屏幕变化
    const resizeFun=(e)=>{
        if(document.querySelector('.img_box_ref') && document.querySelector('.s_body_ref')){
            let imgW=document.querySelector('.img_box_ref').offsetWidth
            let s_body=document.querySelectorAll('.s_body_ref')
            s_body.forEach(dom=>{
                dom.style.height=imgW + 56 + 'px'
            })
        }
    }

    //上一个
    const handlePrev=()=>{
        state.slidePrev()
    }

    //下一个
    const handleNext = ()=>{
        state.slideNext()
    }

    const infoDom=(param)=>{
        return (
            <div className={tStyle.info}>
                <p className={tStyle.name}>{param.name}</p>
                <p className={tStyle.team_info}>{param.label}</p>
           </div>  
        )
    }

    const padding_right=(index,list)=>{
        let right;
        if(platform == 'phone'){
            right=index == list.length - 1 ? '20px':'0px'
        }else if(platform == 'pc'){
            right=index == list.length - 1 ? '60px':'0px'
        }
        return right
    }

    let style=(index,list)=>{
        return props.platform == 'phone'?{
            fontSize: '14px', 
            overflow: 'hidden',
            paddingLeft:'20px', 
            paddingRight:padding_right(index ,list),
            width: '240px', 
        }:{
            fontSize: '14px', 
            overflow: 'hidden',
        }
       
    }
    return (
        <div className={tStyle.swipper_item_box} >
                    <div className={tStyle.s_head}>
                        <h6 className={tStyle.s_title}>{options.title}</h6>
                        {/* {
                            platform != 'phone' && (
                                <div className={tStyle.event_box}>
                                    <div className={tStyle.prev} style={{opacity: actionIdx == 0 ? '0.5':'1'}} onClick={handlePrev}>
                                        <img className={tStyle.prev_img} src={require('../../assets/images/switch1.png')} width="28"/>
                                    </div>
                                    <div className={tStyle.next} style={{opacity: actionIdx == options.list.length ? '0.5':'1'}} onClick={handleNext}>
                                        <img className={tStyle.next_img} src={require('../../assets/images/switch1.png')} width="28"/>
                                    </div>
                                </div>
                            )
                        } */}
                    </div>
                    <div className={`${tStyle.s_body} s_body_ref`} style={{position:'relative',overflow:'hidden'}}>
                        {
                           props.platform == 'pc' &&  actionIdx > 0 ? <div style={{height:'100%',width:btnW + 'px',position:'absolute',top:'0',left:'-1px',zIndex:'5',cursor:'pointer'}}  onClick={handlePrev}>
                                <img src={require('@/assets/images/Button-Team_Previous.png')} height="100%"/>
                            </div>:<></>
                        }
                        {
                          props.platform == 'pc' &&  actionIdx < options.list.length && options.list.length > slidesNum ? <div style={{height:'100%',width:btnW+'px',position:'absolute',top:'0',right:'-1px',zIndex:'5',cursor:'pointer',display:'flex',justifyContent:'flex-end'}} onClick={handleNext}>
                             <img src={require('@/assets/images/Button-Team_Next.png')} height="100%"/>
                           {/* </div>:<></> */}
                           </div>:<></>
                        }
                        {/*  slidesPerView={'auto'} */}
                        <Swiper
                            modules={[]}
                            style={{ width: '100%',height:'100%'}}
                            slidesPerView={props.platform == 'pc' ? slidesNum:'auto'}
                            spaceBetween={props.platform == 'pc' ? 24 : 0}
                            onSwiper={(swiper) =>{
                                setState(swiper);
                            }}
                            noSwiping={platform != 'phone' && true}
                            noSwipingClass={platform != 'phone'&&'swiper-no-swiping'}
                            onSlideChange={() => setActionIdx(state.activeIndex)}
                            onTransitionStart={(swiper)=>{
                                swiper.isEnd ? setActionIdx(options.list.length) : setActionIdx(state.activeIndex);
                            }}
                        >
                           {
                            options.list.map((el,index)=>{
                                return (
                                    <SwiperSlide key={index}  className={`${platform != 'phone'&&'swiper-no-swiping'}`}
                                     style={style(index,options.list)}>
                                         {
                                              <div className={`${tStyle.slide_item_box} slide_item_dom`} style={{height:el.name && el.label ? '100%':el.name ? 'calc(100% - 24px)' : el.label ? 'calc(100% - 24px)':'calc(100% - 4px)'}}>
                                                <div className={`${tStyle.user_img_box} img_box_ref`} style={{paddingBottom:el.name && el.label ? '54px':el.name ? '30px' : el.label ? '30px':'4px'}}>
                                                    <img className={tStyle.user_img} src={el.url} alt="image"/>
                                                    <div className={tStyle.show} style={showStyle}>
                                                        {infoDom(el)}
                                                        <div className={`${tStyle.user_info} user_info_dom`} style={infoStyle}>{el.content}</div>
                                                    </div>
                                                    <div className={tStyle.tag_box}>
                                                        {
                                                            infoDom(el)
                                                        }
                                                    </div>
                                                </div>
                                            </div> 
                                        }
                                    </SwiperSlide>
                                )
                            })
                           }
                        </Swiper>
                    </div>
                </div>
    )
    
}
export default connect(
    state=>({
        platform:state.platform
    })
)(SwiperItemDom);