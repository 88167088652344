/* 
封装navlink
path--跳转路径
label--标题
*/
import React from 'react'
import { useNavigate} from 'react-router'
import {connect} from 'react-redux'

 const NavLink=(props)=> {
  const navigate=useNavigate()
  const {label="",path="",state={}}=props
  const handleNav=()=>{
    //路由跳转
    navigate(path,{replace:true,state})
  }
  return (
    <>
        <div onClick={handleNav}>{label}</div>
    </>
  )
}

export default connect(
  state=>({}),
)(NavLink)
