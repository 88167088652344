import React, { useState, useEffect , Fragment} from 'react'
import { useLocation , useSearchParams} from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {connect} from 'react-redux'
import {newDeetail} from '@/api/website'
import dStyle from './index.module.scss'
import './img.css'
import './quill.bubble.css'
import './quill.core.css'
import './quill.snow.css'

import Foolter from '@/layout/Footer'

let oldParamLang=window.location.hash.split('/')[window.location.hash.split('/').length - 1];

function NewsDetails(props) {
    //hook 获取 state 方式的参数
    // const location = useLocation()
    // const { row } = location.state
    const [searchParams]=useSearchParams()
    const rayvcNewsId=searchParams.get('rayvcNewsId')
    const [row,setRow]=useState()
    const {t,i18n}=useTranslation()
    const {language}=i18n

    const requestList = async () => {
        const res=await newDeetail({rayvcNewsId})
        if(res.code == 200){
            console.log(res.data)
            setRow(res.data)
        }
    };

    useEffect(() => {
        requestList();
        if(props.platform == 'phone') document.querySelector('#app').style.overflow = "hidden auto" 
        setTimeout(()=>{
            let p_list=document.querySelector('.html_style').querySelectorAll('p')
            p_list.forEach(p=>{
                if(!p.innerHTML.includes('br')){
                    p.classList.add('text_p')
                }
            })
        },100)
    }, [])

    return (
        <>
        {/* <div dangerouslySetInnerHTML={{__html:state.rayvcNewsContent}}></div> */}
           {row &&  <div className={dStyle.wrapper}>
                <div className={dStyle.banner_box}>
                    <div className={dStyle.h5}>{row.rayvcNewsTitle}</div>
                    <p className={dStyle.date}>{row.createTime}</p>
                </div> 
                
                <div className={dStyle.cont_box}>
                    <div className={dStyle.img_box}>
                        <img src={process.env.REACT_APP_IMG_URL + row.rayvcNewsImgUrl} width="100%"/>
                    </div>
                    <div className={dStyle.preface}>
                    {row.rayvcNewsIntroduce}
                    </div>
                    <div className='html_style ql-editor' dangerouslySetInnerHTML={{__html:row.rayvcNewsContent}}></div>
                </div> 
            </div>
            }
            <Foolter/>
        </>
        
    )
}
export default connect(
    state=>({
        platform:state.platform
    })
)(NewsDetails);
