import request from '../utils/request'
import qs from 'query-str'

//首页数据接口查询
export function getHomeDatas(){
    return request({
        url:'/home/homeList',
        method:'post'
    })
}

//通知
export function notice(){
    return request({
        url:'/system/notice/1',
        method:'get'
    })
}

//业绩页简介列表(PC)
export function introduceList(){
    return request({
        url:'/system/introduce/introduceList',
        method:'get'
    })
}

//查询业绩类型列表(PC)
export function rayvcAchievementList(params){
    return request({
        url:'/system/achievement/rayvcAchievementList',
        method:'get',
        params
    })
}

//查询新闻列表(PC)
export function newsList(query){
    const url =  qs.stringify(query, '/system/news/newsList')
    return request({
        url,
        method:'get'
    })
}

//查询团队成员列表(PC)
export function teamList(query){
    return request({
        url:qs.stringify(query,'/system/member/teamList'),
        method:'get',
    })
}

//新闻详情
export function newDeetail(query){
    return request({
        url:qs.stringify(query,'/system/news/getNewInfo'),
        method:'get',
    })
}