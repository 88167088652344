import {SET_FOOTER} from '../constant'

const footer=sessionStorage.getItem('footer') ? JSON.parse(sessionStorage.getItem('footer')):{}
export default function footerReducer(preState=footer,action){
    const {type,data}=action
    switch(type){
        case SET_FOOTER:
            sessionStorage.setItem('footer',JSON.stringify(data))
            return data;
        default :
            return  preState;
    }
}