import React from 'react';
import ReactDOM from 'react-dom/client';
import {HashRouter} from 'react-router-dom'
import store from './redux/store'
import {Provider} from 'react-redux'
import './assets/fonts/iconfont.css'
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './utils/rem.js'
import './i18n'

import { AliveScope } from 'react-activation';

// console.log(process.env)
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // 关闭严格模式   方式重复调用生命周期函数
  // <React.StrictMode>
    <Provider store={store}>
      <HashRouter>
        <AliveScope>
          <App />
        </AliveScope>
      </HashRouter>
    </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
