import React, { useSyncExternalStore } from 'react'
import uStyle from './index.module.scss'

export default function UpAnimatie() {
  return (
    <div className={uStyle.controller}>
        <img className={uStyle.img} src={require('../../assets/images/up.png')} width="23"/>
    </div>
  )
}
