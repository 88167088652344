import React, { useEffect,useState, Fragment } from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useActivate } from 'react-activation'
import pStyle from './index.module.scss'

import {introduceList,rayvcAchievementList} from '@/api/website'

import Foolter from '@/layout/Footer'

function Pc(props) {
    const { pathname, state , search} = useLocation()
    const {t,i18n}=useTranslation()
    const {language}=i18n;
    const [height,setHeight]=useState(0)
    const [list,setList]=useState([]);
    const [investmentPtojects,setInvestmentPtojects]=useState([])
    const [project,setProject]=useState({})

    const resizeUpdate=(e)=>{
        let h=e.target.innerHeight
        setHeight(h)
    }
    
    //业绩基本信息
    const getIntroduceList=async ()=>{
        const res=await introduceList()
        if(res.code == 200){
            for(let i=0;i<res.rows.length;i++){
                let el=res.rows[i]
                let data = await getRayvcAchievementList( i+1 )
                    let projects=data?data.map(ev=>{
                        return {
                            ...ev,
                            rayvcAchievementName:ev?.[t('portfolio.rayvcAchievementName')] || '',
                            rayvcAchievementDesc:ev?.[t('portfolio.rayvcAchievementDesc')] || '',
                            rayvcAchievementTitle:ev?.[t('portfolio.rayvcAchievementTitle')].split('#').splice(1) || ''}
                    }):[]
                    setList(arr=>{
                        arr.push({
                            introduceImgUrl:el.introduceImgUrl,
                            introduceTitle:el?.[t('portfolio.introduceTitle')] || '',
                            achievementTitle:el?.[t('portfolio.achievementTitle')] || '',
                            introduceContent:el?.[t('portfolio.introduceContent')] || '',
                            introduceId:el.introduceId,
                            projects
                        })
                        return [...arr]
                    })
            }
        }
    }

    //业绩-公司简介
    const getRayvcAchievementList=async (rayvcAchievementType)=>{
        let param={pageNum:1,pageSize:100}
        if(rayvcAchievementType){
            param.rayvcAchievementType=rayvcAchievementType
        }
        const res=await rayvcAchievementList(param)
        if(res.code == 200){
            if(rayvcAchievementType){
                // let newObj={};
                // newObj[introduceId]=res.rows.map(el=>({...el,rayvcAchievementTitle:el.rayvcAchievementTitle.split('#').splice(1)}))
                // setProject(obj=>Object.assign(obj,newObj))
                return res.rows
            }else{
                let allRows=res.rows&&res.rows.length?res.rows.map(el=>{
                    return {
                        ...el,
                        rayvcAchievementName:el?.[t('portfolio.rayvcAchievementName')] || '',
                        rayvcAchievementDesc:el?.[t('portfolio.rayvcAchievementDesc')] || '',
                        rayvcAchievementTitle:el?.[t('portfolio.rayvcAchievementTitle')].split('#').splice(1)}
                }):[]
                setInvestmentPtojects(allRows)
            }
        }
    }

    const getAll=async ()=>{
       await getIntroduceList()
       await getRayvcAchievementList()
       const index=search?search.split('=')[1]:null
        if (index != null) {
            setTimeout(() => {
                let bodyHeight = document.body.offsetHeight
                console.log(bodyHeight)
                if (document.querySelector('#app')) {
                    document.querySelector('#app').scrollTop = bodyHeight * index;
                }
            },10);
        }
    }
    //缓存时候的监听 didActivate
    useActivate(() => {
        let index=window.location.href.includes('?') ? window.location.href.split('?')[1].split('=')[1] : null 
        if (index != null) {
            setTimeout(() => {
                let bodyHeight = document.body.offsetHeight
                if (document.querySelector('#app')) {
                    document.querySelector('#app').scrollTop = bodyHeight * index;
                }
            });
        }
    })
    useEffect(() => {
        getAll()
        
        //页面窗口变化的时候监听
        window.addEventListener('resize',resizeUpdate)
        //组件销毁
        return ()=>{
            //移除页面窗口变化监听
            window.removeEventListener('resaize',resizeUpdate)
        }
    }, [])

    const listDom = () => {
        return list.map((el, index) => {
                    return <Fragment key={index}>
                        {
                            index % 2 == 0 ? <>
                                <div className={pStyle.img_box} style={{ backgroundImage: `url(${process.env.REACT_APP_IMG_URL+ el.introduceImgUrl})` }}></div>
                                <div className={pStyle.right_box} style={{paddingTop:height < 800 ? '100px':'calc((100vh - 600px)/2)'}}>
                                    <div className={pStyle.mode_box}>
                                    <div className={pStyle.info_box}>
                                        <h5 className={pStyle.h5}>{el.introduceTitle}<br />{el.achievementTitle}</h5>
                                        <p className={pStyle.info}>{el.introduceContent}</p>
                                    </div>
                                    <ul className={pStyle.projects_box}>
                                        {/* {project[el.introduceId] && projectsDom(project[el.introduceId])} */}
                                        {projectsDom(el.projects)}
                                    </ul>
                                    </div>
                                </div>
                            </>
                                : <>
                                    <div className={pStyle.right_box} style={{paddingTop:height < 800 ? '100px':'calc((100vh - 600px)/2)'}}>
                                    <div className={pStyle.mode_box}>
                                        <div className={pStyle.info_box}>
                                            <h5 className={pStyle.h5}>{el.introduceTitle}<br />{el.achievementTitle}</h5>
                                            <p className={pStyle.info}>{el.introduceContent}</p>
                                        </div>
                                        <ul className={pStyle.projects_box}>
                                            {/* {project[el.introduceId] && projectsDom(project[el.introduceId])} */}
                                            {projectsDom(el.projects)}
                                        </ul>
                                        </div>
                                    </div>
                                    <div className={pStyle.img_box} style={{ backgroundImage: `url(${process.env.REACT_APP_IMG_URL+ el.introduceImgUrl})` }}></div>
                                </>
                        }
                    </Fragment>
                })
    }

    //pc
    const projectsDom = (projects) => {
        return projects ? projects.map((item, index) => {
            return (
                <li className={pStyle.li} key={index}>
                    <div className={pStyle.logo_box}>
                        <img src={process.env.REACT_APP_IMG_URL + item.rayvcAchievementImgUrl} width="100%" />
                    </div>
                    <div className={pStyle.cont_box}>
                        <h6 className={pStyle.h6}>{item.rayvcAchievementName}</h6>
                        <div className={pStyle.tag_box}>{tagDom(item.rayvcAchievementTitle)}</div>
                        <div className={pStyle.cont_text}>{item.rayvcAchievementDesc}</div>
                    </div>
                </li>
            )
        }) : <></>
    }

    //pc 小标签dom
    const tagDom = (tags) => {
        return tags.map((tag, index) => {
            return (
                <span className={pStyle.tag_item} key={index}>#{tag}</span>
            )
        })
    }

    const investmentDom = () => {
        return (
            <div className={pStyle.investment_box}>
                    <h5 className={pStyle.investment_h5}>{t('portfolio.allPortfolioTitle')}</h5>
                    <div className={pStyle.total_performance}>
                        {
                            investmentPtojects.map((item,index)=>{
                                return (
                                    <div className={pStyle.performance_item} key={index}>
                                        <div className={pStyle.performance_logo} onClick={()=>item.rayvcAchievementUrl && window.open(item.rayvcAchievementUrl ,'_blank')}>
                                            <img src={process.env.REACT_APP_IMG_URL+ item.rayvcAchievementImgUrl} width="100%" />
                                            {
                                                item.rayvcAchievementUrl ? <img className={pStyle.link_icon} src={require('@/assets/images/Icon-Link.png')} width="12"/>:<></>
                                            }
                                        </div>
                                        <div className={pStyle.performance_titel_info}>
                                            <h5 className={pStyle.enterprise_name} onClick={()=>item.rayvcAchievementUrl && window.open(item.rayvcAchievementUrl ,'_blank')}>{item.rayvcAchievementName}</h5>
                                            <div className={pStyle.tag_box} onClick={()=>item.rayvcAchievementUrl && window.open(item.rayvcAchievementUrl ,'_blank')}>{tagDom(item.rayvcAchievementTitle)}</div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            )
    }

    return (
        <>
        <div className={pStyle.wrapper}>
            {
                listDom()
            }
            {
                investmentDom()
            }
            </div>
            <Foolter/>
        </>
    )
}


export default Pc;