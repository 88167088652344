import en from './en.json'
import cn from './cn.json'

export default {
    en:{
        translation:en
    },
    cn:{
        translation:cn
    }
}