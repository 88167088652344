import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate,useSearchParams } from 'react-router-dom'
import { useAliveController } from 'react-activation';
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { navIndexs } from '../../locales/educationData'
import styles from './index.module.scss'
import NavLink from '../../components/NavLink'
const logo = require('../../assets/images/logo.png')

const menus = [require('../../assets/images/menu.png'), require('../../assets/images/close.png')]
function Navbar(props) {
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const [opacity, setOpacity] = useState(0)

  //react-i18next 返回的是字符串格式，利用数组获取对应需要的遍历对象
  const navs = navIndexs.map(k => {
    return {
      label: t(`navs.${k}.label`),
      path: t(`navs.${k}.path`)
    }
  });
  console.log(navs)
  /* 
    platform store做手机端和pc端兼容
  */
  const { platform } = props
  const [menuState, setMenuState] = useState(false)
  const [linepPatnName, setLinkPathName] = useState(null)
  const { pathname, state, search } = useLocation()
  const [searchParams]=useSearchParams()
  const navigate = useNavigate()
  const { dropScope } = useAliveController()

  //
  const [form,setForm]=useState(null)
  //
  const changeLang = () => {
    i18n.changeLanguage(language == 'en' ? 'cn' : 'en')
    let routes = pathname.split('/').filter(d => d);
    let pathName = routes.slice(0, routes.length - 1).join('/'); //路由path
    navigate(`/${pathName}/${i18n.language}${search}`)
    dropScope() //切换中英文时清除页面缓存 keep-alive
  }

  const handleMenu = () => {
    setMenuState(status => !status)
  }
  // 选中工具
  const switchLocation = (_pathname) => {
    //获取-缓存中的值
    const nav= searchParams.get('active') ? `/${searchParams.get('active')}`:null;
    navs.forEach(n => {
      if (n.path == _pathname) {
        new Promise(resolve => {
          setLinkPathName(() => {
            resolve(_pathname)
            return _pathname
          })
        })
        /* 
         拿到更新后的值，做进一步处理
        .then(res=>{
          console.log(res)
        }) */
      } else if (nav && nav == n.path) {
        new Promise(resolve => {
          setLinkPathName(() => {
            resolve(nav)
            return nav
          })
        })
      }
    })
  }
  // //监听路由变化
  useEffect(() => {
    // console.log(pathname,linepPatnName)
    //减少重复监听 -- 后续国际化语言放开
    let routes=pathname.split('/').filter(d=>d);
    let pathName='/'+routes.slice(0,routes.length - 1).join('/'); //路由path
    if(pathName != linepPatnName){
      switchLocation(pathName)
    }

    if (pathname != linepPatnName) {  //nav 高亮操作
      switchLocation(pathname)
    }
    if (document.querySelector('#app')) {
      document.querySelector('#app').scrollTop = 0;
    }
    if (platform == 'phone') {
      setMenuState(false)
      setOpacity(0)

      const body_h = document.body.clientHeight * 0.4
      document.querySelector('#app').addEventListener('scroll', (e) => {
        if(window.location.href.split('#')[1] == `/news/${language}`){  //新闻页分页影响监听
          if (document.querySelector('#scrollableDiv').scrollTop > body_h) {
            setOpacity(0.5)
          } else {
            setOpacity(0)
          }
        }else{
          if (document.querySelector('#app').scrollTop > body_h) {
            setOpacity(0.5)
          } else {
            setOpacity(0)
          }
        }
       
      },true)
    } else {
      // setOpacity(0.5)
      setOpacity(0)
      const body_h = 65
      document.querySelector('#app').addEventListener('scroll', (e) => {
        if(window.location.href.split('#')[1] == `/news/${language}`){ //新闻页分页影响监听
          if (document.querySelector('#scrollableDiv').scrollTop > body_h) {
            setOpacity(0.5)
          } else {
            setOpacity(0)
          }
        }else{
        if (document.querySelector('#app').scrollTop > body_h) {
          setOpacity(0.5)
        } else {
          setOpacity(0)
        }
      }
      },true)
    }
  }, [pathname, linepPatnName, platform])
  //  <div className={styles.nav_bar} style={{backgroundColor:menuState?'#18181B':rgba(0, 0, 0, ${opacity})`,transition:'opacity 0.2s'}}>
  return (
    <>
      <div className={styles.nav_bar} style={{ backgroundColor: menuState ? '#18181B' : platform == 'pc' ? opacity > 0 ? `rgba(0, 0, 0, ${opacity})` : '#18181B' : `rgba(0, 0, 0, ${opacity})`, transition: 'opacity 0.2s' }}>
        <div className={styles.logo}>
          <img src={logo} height="56" onClick={() => navigate('/')} style={{ cursor: 'pointer' }} />
        </div>
        {
          platform == 'phone' && (
            <div className={styles.phone_icon} onClick={handleMenu}>
              <img src={menuState ? menus[1] : menus[0]} width="40" />
            </div>
          )
        }
        <div className={menuState ? styles.phone_nav_box : styles.nav_box}>
          <ul className={`${styles.nav_cont} ${styles.text_color_fff}`}>
            {
              navs.map(el => {
                return <li key={el.label} className={`${styles.nav_item} ${linepPatnName == el.path && styles.nav_active}`}>
                  <NavLink path={`${el.path}/${language}`} label={el.label} state={{ a: 1, b: 3 }} />
                </li>
              })
            }
            {/* 后续国际化 加上事件 */}
            {<li className={styles.nav_item} style={{padding:platform == 'pc'?'19px 15px':''}} onClick={() => changeLang()}>
                {/* <span className={`iconfont ${language == 'cn' ? 'icon-zhongyingwenqiehuan-zhongwen' : 'icon-zhongyingwenqiehuan-yingwen'}`} style={{ fontSize: '24px' }}></span> */}
                {
                  platform == 'phone'?
                    (
                      <div className={styles.languageCheckPhone}>
                        <span>{language == 'cn' ? '简体中文' : 'ENGLISH'}</span> / <span style={{opacity:'0.5'}}>{language == 'cn' ? 'ENGLISH' : '简体中文'}</span>
                      </div>
                    ):
                    (
                      <div className={styles.languageCheckPc}>
                          <span>{language == 'cn' ? '中' : 'EN'}</span> / <span style={{opacity:'0.5'}}>{language == 'cn' ? 'EN' : '中'}</span>
                      </div>  
                    )
                }
              </li>
            }
          </ul>
        </div>
      </div>
      {
        platform == 'phone' && menuState && <div className={styles.Mask} onClick={handleMenu}></div>
      }
    </>
  )

}

export default connect(
  state => ({
    platform: state.platform,
  }),
)(Navbar)
