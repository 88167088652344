import React, { Component } from 'react'
import { connect } from 'react-redux'
import { KeepAlive } from 'react-activation';
import Dialog from '../../components/Dialog'
import {platdialog} from '../../redux/actions/dialog'

import Banner  from './Banner'
import Content from './Content'
import Foolter from '@/layout/Footer'

/**api*/
import  {notice} from '@/api/website'

class Home extends Component {
  constructor(props){
    super(props)
    this.state={
      loading:false,
      dialogVisible:'',
      option:undefined,
    }
  }

  handleClose(v){
    this.props.platdialog(1)
  }
  async getData(){
    const res=await notice()
    if(res.code == 200){
      this.setState({
        dialogVisible:res.data.status,
        option:{
          noticeTitle:res.data.noticeTitle,
          noticeTitleTwo:res.data.noticeTitleTwo,
          noticeContentTwo:res.data.noticeContentTwo
        }
      })
    }
  }
  componentDidMount(){
    this.getData()
    this.setState({
      loading:true
    })
  }

  render() {
    const {loading}=this.state
    const {dialog}=this.props
    return (
        <>
          <div>
          {
            loading && 
            <div>
              {
               this.state.dialogVisible != '' && this.state.dialogVisible == 0 && dialog == 0 ? <Dialog option={this.state.option} close={(v)=>this.handleClose(v)}/>  : <></>
              }
                <Banner/>
                <KeepAlive when={true}>
                  <Content/>
                </KeepAlive>
            </div>
          }
        </div>
        <Foolter/>
        </>
    )
  }
}
export default connect(
  state=>({
    dialog:state.dialog,
    platform: state.platform
  }),
  {platdialog}
)(Home)
