import React, { useState, useEffect, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import Swiper from 'swiper'
import 'swiper/css';
import 'swiper/css/scrollbar';

import pStyle from './phone.module.scss'

import Foolter from '@/layout/Footer'

export default function PhoneMore(props) {
    const { investmentPtojects,show } = props
    const {t,i18n}=useTranslation()
    useEffect(() => {
        setTimeout(() => {
            var swiper = new Swiper(".mySwiper", {
                direction: "vertical",
                slidesPerView: "auto",
                freeMode: true,
                scrollbar: {
                    el: ".swiper-scrollbar",
                },
                mousewheel: true,
            });
        }, 100);
    }, [])
    const handleDetail=(row)=>{
        // show(true,row)
        if(row.rayvcAchievementUrl){
            window.open(row.rayvcAchievementUrl,'_blank')
        }
    }

    return (
        <>
            <div className="swiper mySwiper" style={{ height: '100vh', width: '100vw' }}>
                <div className="swiper-wrapper">
                    <div className="swiper-slide" style={{ height: 'auto' }}>
                        <div className={pStyle.investment_box}>
                            <div className={pStyle.investment_bg}></div>
                            <h5 className={pStyle.investment_h5}>{t('portfolio.allPortfolioTitle')}</h5>
                            <ul className={pStyle.list_box}>
                                {
                                    investmentPtojects.map((el, index) => {
                                        return <Fragment key={index}>
                                            <li className={pStyle.list_item} onClick={()=>handleDetail(el)}>
                                                <img src={process.env.REACT_APP_IMG_URL+ el.rayvcAchievementImgUrl} width="100%" />
                                                {
                                                   el.rayvcAchievementUrl ? <img className={pStyle.link_icon} src={require('@/assets/images/Icon-Link.png')} width="12"/>:<></>
                                                }
                                            </li>
                                        </Fragment>
                                    })
                                }
                            </ul>
                        </div>
                        <Foolter />
                    </div>
                </div>
            </div>
                
                    
        </>
    )
}
