import React from 'react'
import pStyle from './phone.module.scss'

export default function Drawer(props) {
    const {visible,close,detail}=props

     //pc 小标签dom
     const tagDom = (tags) => {
        return tags.map((tag, index) => {
            return (
                <span className={pStyle.tag_item} style={{marginBottom:'4px',display:'inline-block',marginRight:'6px'}} key={index}>#{tag}</span>
            )
        })
    }
  return (
   <>
     {visible && <div className={pStyle.phone_mask}></div>}
        <div className={pStyle.phone_detail} style={{bottom:visible ? '0' : '-100vh'}}>
                        <img className={pStyle.phone_close} src={require('@/assets/images/close.png')} width="30" onClick={()=>close(false)}/>
                        {
                            visible && <div className={pStyle.phone_cont} >
                            <div className={pStyle.phone_icon_box}>
                                <div className={pStyle.phone_logo_box}>
                                    <img src={detail.logo} width="100%" />
                                </div>
                                <div className={pStyle.phone_tag}>
                                    <h6 className={pStyle.phone_h6}>{detail.label}</h6>
                                    {tagDom(detail.tags)}
                                </div>
                            </div>
                            <div className={pStyle.phone_content} style={{height:'220px',overflowY:'auto'}}>
                                {detail.content}
                            </div>
                        </div>
                        }
                    </div>
   </>
    
  )
}
