import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
// import { Swiper, SwiperSlide  } from 'swiper/react';
// import 'swiper/css';
import { useTranslation } from 'react-i18next'
import { useActivate } from 'react-activation'
import tStyle from './index.module.scss';

import SwiperItemDom from './SwiperItemDom';
import Foolter from '@/layout/Footer';

import {teamList} from '@/api/website';

let timer=null;
function check(cb,time=500){
    if(timer){
        clearInterval(timer);
        timer=null;
    }
    timer = setInterval(() => {
        let dom=document.querySelector('.slide_item_dom');
        if(dom){
            cb();
            clearInterval(timer);
            timer=null;
        }
    }, time);
}


function Team(props) {
    const {t,i18n}=useTranslation()
    const [slidesNum,setSlidesNum]=useState(7)
    const [btnW,setBtnW]=useState(240)
    const [infoStyle,setInfoStyle]=useState({})
    const [showStyle,setShowStyle]=useState({})
    //datas
    const [options,setOptions]=useState([])
    const [loading,setLoading]=useState(true)

    const getTeamList=async (teamMemberType,title)=>{
        const res=await teamList({teamMemberType})
        if(res.code == 200){
            let swList=res.rows.map(el=>{
                return {
                    name:el?.[t('team.teamMemberName')] || '',
                    label:el?.[t('team.teamMemberTitle')] || '',
                    content:el?.[t('team.teamMemberContent')] || '',
                    url:`${process.env.REACT_APP_IMG_URL}${el.teamMemberImgUrl}`
                }
            })
            setOptions(arr=>{
                arr.push({title,list:swList})
                return [...arr]
            })
        }
    }

    const getAll=async ()=>{
        await getTeamList(0,t('team.executiveTeam'))
        await getTeamList(1,t('team.consultant'))
        setLoading(false)
    }
    //缓存时候的监听 didActivate
    useActivate(() => {
        let bg_dom=document.querySelector('.default_dg')
        bg_dom.style.top='0px'
        bg_dom.style.bottom='unset'
        bg_dom.style.position='fixed'
    })
    useEffect(() => {
        getAll()
        if(props.platform == 'phone') document.querySelector('#app').style.overflow = "hidden auto" 
        let bg_dom=document.querySelector('.default_dg')
        let tem_wrapper_dom=document.querySelector('.tem_defalut_wrapper')
        document.querySelector('#app').addEventListener('scroll', (e) => {
            if(bg_dom){
                let pageH = document.body.offsetHeight
                let scrollTop = e.target.scrollTop
                  if (scrollTop > tem_wrapper_dom.offsetHeight - pageH) {
                        bg_dom.style.top='unset'
                        bg_dom.style.bottom='0px'
                        bg_dom.style.position='absolute'
                  } else {
                        bg_dom.style.top='0px'
                        bg_dom.style.bottom='unset'
                        bg_dom.style.position='fixed'   
                  }
            }
        }, true)
        if(window.innerWidth - 40 < 1680){
               setSlidesNum(parseInt((window.innerWidth - 40) / 240))
               setBtnW((window.innerWidth - 80 - (parseInt((window.innerWidth - 40) / 240) - 1) * 24) / parseInt((window.innerWidth - 40) / 240))
            //    setTimeout(() => {
            //     setUserInfoStyle()
            //    }, 300);
            check(()=>{
                setUserInfoStyle();
            },300)
        }else{
        //     setTimeout(() => {
        //         setUserInfoStyle()
        //    }, 1000);
            check(()=>{
                setUserInfoStyle();
            })
        }
       
        window.onresize=function(e){
            setTimeout(() => {
                if(window.innerWidth - 40 < 1680){
                    setSlidesNum(parseInt((window.innerWidth - 40) / 240))
                    setBtnW((window.innerWidth - 80 - (parseInt((window.innerWidth - 40) / 240) - 1) * 24) / parseInt((window.innerWidth - 40) / 240))
                    setUserInfoStyle()
                }else{
                    setSlidesNum(7)
                    setBtnW(240)
                    setUserInfoStyle()
                }
            }, 200);
           }
    }, [])

    const setUserInfoStyle=()=>{
        setTimeout(() => {
            let h=null;
            if(!document.querySelector('.slide_item_dom')){
                setUserInfoStyle()
            }
            if(document.querySelector('.slide_item_dom') && document.querySelector('.slide_item_dom').offsetWidth <= 235){
                h=190 / 19;
                let info_style={
                    maxHeight:'190px',
                    marginTop:'7px',
                }
                setInfoStyle(info_style)
            }else if(document.querySelector('.slide_item_dom') && document.querySelector('.slide_item_dom').offsetWidth > 235 && document.querySelector('.slide_item_dom') && document.querySelector('.slide_item_dom').offsetWidth <= 250){
                h=209 / 19;
                let info_style={
                    maxHeight:'209px',
                }
                setInfoStyle(info_style)
            }else if(document.querySelector('.slide_item_dom') && document.querySelector('.slide_item_dom').offsetWidth > 250 && document.querySelector('.slide_item_dom') && document.querySelector('.slide_item_dom').offsetWidth <= 265){
                h=228 / 19;
                let info_style={
                    maxHeight:'228px',
                }
                setInfoStyle(info_style)
            }else if(document.querySelector('.slide_item_dom') && document.querySelector('.slide_item_dom').offsetWidth > 265){
                h=247 / 19;
                let info_style={
                    maxHeight:'247px',
                }
                setInfoStyle(info_style)
            }else{
                setInfoStyle({})
                setShowStyle({})
            }
            if(h){
                console.log('----',document.querySelector('#app').style)
                const app=document.querySelector('#app');
                app.style.setProperty('--line-clamp',h || null)
            }
        }, 100);
    }
    const title_dom = () => {
        return props.platform == 'phone' ? <>我们是<br />未来城市探索者</> : <>我们是未来城市探索者</>
    }
    const title_en_dom = () => {
        return props.platform == 'phone' ? <>WE ARE THE <br /> EXPLORER OF THE <br />FUTURE CITY</> : <>THE EXPLORER OF THE FUTURE CITY</>
    }
    
    return (
        <>
            <div className={`${tStyle.wrapper} tem_defalut_wrapper`} >
                <div className={`${tStyle.bg} default_dg`}></div>
                <div style={{width:'100%',height:'100%',position:'fixed',top:'0',left:'0',zIndex:'-2',backgroundColor:'#18181B'}}></div>
                <div className={tStyle.banner_box}>
                    <h5 className={tStyle.title}>{title_dom()}</h5>
                    <p className={tStyle.title_en}>{title_en_dom()}</p>
                </div>
                <div className={`${tStyle.swipper_box} swiper_box`}>
                    {
                       loading ? <div>loading...</div> : options.map((el, index) => <SwiperItemDom key={index} slidesNum={slidesNum} infoStyle={infoStyle} showStyle={showStyle} btnW={btnW} options={el}></SwiperItemDom>)
                    }
                </div>
            </div>
            <Foolter />
        </>
    )
}
export default connect(
    state => ({
        platform: state.platform
    })
)(Team);