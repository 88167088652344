/* 
	该文件用于汇总所有的reducer为一个总的reducer
*/
//引入combineReducers，用于汇总多个reducer
import {combineReducers} from 'redux'
//引入为Count组件服务的reducer
import count from './count'
import platform from './platform'
import footer from './footer'
import dialog from './dialog'
import alive from './alive'

//汇总所有的reducer变为一个总的reducer
export default  combineReducers({
	count,
	platform,
	footer,
	dialog,
	alive
})
