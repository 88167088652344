import { SET_PLATFORM } from '../constant'
//判断是什么显示端
const initPlatform='pc'
export default function platformReducers(preplatform=initPlatform,action){
    const {type,data}=action
    switch (type){
        case SET_PLATFORM:
            return data
        default :
            return preplatform
    }
} 