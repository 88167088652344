import React, { useState,useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useActivate } from 'react-activation'
import { Swiper, SwiperSlide } from 'swiper/react';
import { useTranslation } from 'react-i18next'
import 'swiper/css';
import 'swiper/css/scrollbar';

import pStyle from './phone.module.scss'

import {introduceList,rayvcAchievementList} from '@/api/website'

import UpAnimatie from '@/components/UpAnimatie'
import PhoneMore from './phoneMore'
import PhoneCont from './phoneCont'
import Drawer from './drawer'

function Phone(props) {
    const { pathname, state , search } = useLocation()

    const [visible,setVisible]=useState(false)
    const [detail,setDetail]=useState({})

    const [list,setList]=useState([]);
    const [investmentPtojects,setInvestmentPtojects]=useState([])
    const [num,setNum]=useState(0)
    const [loading,setLoading]=useState(false)
    const {t,i18n}=useTranslation()
    //业绩基本信息
    const getIntroduceList=async ()=>{
        const res=await introduceList()
        if(res.code == 200){
            for(let i=0;i<res.rows.length;i++){
                let el=res.rows[i]
                let data = await getRayvcAchievementList( i + 1)
                    let projects=data?data.map(ev=>{
                            return {
                                ...ev,
                                rayvcAchievementImgUrl:process.env.REACT_APP_IMG_URL + ev.rayvcAchievementImgUrl,
                                rayvcAchievementName:ev?.[t('portfolio.rayvcAchievementName')] || '',
                                rayvcAchievementDesc:ev?.[t('portfolio.rayvcAchievementDesc')] || '',
                                rayvcAchievementTitle:ev?.[t('portfolio.rayvcAchievementTitle')].split('#').splice(1) || ''
                            }
                    }):[]
                    setList(arr=>{
                        arr.push({
                            introduceImgUrl:process.env.REACT_APP_IMG_URL + el.introduceImgUrl,
                            introduceTitle:el?.[t('portfolio.introduceTitle')],
                            achievementTitle:el?.[t('portfolio.achievementTitle')],
                            introduceContent:el?.[t('portfolio.introduceContent')],
                            introduceId:el?.[t('portfolio.introduceId')],
                            projects
                        })
                        return [...arr]
                    })
            }
        }
    }

    //业绩-公司简介
    const getRayvcAchievementList=async (rayvcAchievementType)=>{
        let param={pageNum:1,pageSize:100}
        if(rayvcAchievementType){
            param.rayvcAchievementType=rayvcAchievementType
        }
        const res=await rayvcAchievementList(param)
        if(res.code == 200){
            if(rayvcAchievementType){
                return res.rows
            }else{
                let allRows=res.rows&&res.rows.length?res.rows.map(el=>{
                    return {
                        ...el,
                        rayvcAchievementTitle:el?.[t('portfolio.rayvcAchievementTitle')].split('#').splice(1)
                    }
                }):[]
                setInvestmentPtojects(allRows)
            }
        }
    }
 //缓存时候的监听 didActivate
 useActivate(() => {
    let num=window.location.href.includes('?') ? window.location.href.split('?')[1].split('=')[1] : null 
    if (num != null) {
        setNum(num)
    }
})
    const getAll=async ()=>{
        setLoading(true)
        await getIntroduceList()
        await getRayvcAchievementList()
        const num=search?search.split('=')[1]:null
        setLoading(false)
        if (num != null) {
            setNum(num)
        }
    }

    useEffect(() => {
        getAll()
    }, [])

    const handleShow=(v,row)=>{
        // console.log(v)
        // console.log(row)
        // setVisible(v)
        // setDetail(row)
    }
    const drawerClose=(s)=>{
        // setVisible(false)
    }

    //pc 小标签dom
    const tagDom = (tags) => {
        return tags.map((tag, index) => {
            return (
                <span className={pStyle.tag_item} style={{marginBottom:'4px',display:'inline-block',marginRight:'6px'}} key={index}>#{tag}</span>
            )
        })
    }

    return (
         <div className={pStyle.wrapper}>
            {loading ?<></> : <Swiper style={{ height: '100vh', width: '100vw' }} direction='vertical' initialSlide={num || 0}>
                {
                    list.map((el, index) => {
                        return <SwiperSlide key={index} >
                            <div className={pStyle.img_box} style={{ backgroundImage: `url(${el.introduceImgUrl})` }}>
                                <h5 className={pStyle.phone_h5}>{el.introduceTitle}<br />{el.achievementTitle}</h5>
                            </div>
                            <div className={pStyle.right_box} >
                                <div className={pStyle.projects_box}>
                                    <Swiper
                                        modules={[]}
                                        style={{ width: '100%', height: '100%' }}
                                        slidesPerView={'auto'}
                                        spaceBetween={0}
                                    >
                                        {
                                            el.projects && el.projects.map((item, index2) => {
                                                return (
                                                    <SwiperSlide key={index2}
                                                        style={{
                                                            paddingRight:index2 == el.projects.length - 1 ?  '.5333rem' : '',
                                                            marginLeft:'.5333rem',
                                                            width: '79vw',
                                                            overflow: 'hidden'
                                                        }}
                                                    >
                                                        {
                                                            <div className={pStyle.phone_cont}>
                                                                <div className={pStyle.phone_icon_box}>
                                                                    <div className={pStyle.phone_logo_box}>
                                                                        <img src={item.rayvcAchievementImgUrl} width="100%" />
                                                                    </div>
                                                                    <div className={pStyle.phone_tag}>
                                                                        <h6 className={pStyle.phone_h6}>{item.rayvcAchievementName}</h6>
                                                                        {tagDom(item.rayvcAchievementTitle)}
                                                                    </div>
                                                                </div>
                                                                <div className={pStyle.phone_content}>
                                                                    {/* {item.content} */}
                                                                    <PhoneCont cont={item.rayvcAchievementDesc}/>
                                                                </div>
                                                            </div>
                                                           
                                                        }
                                                    </SwiperSlide>
                                                )
                                            })
                                        }
                                    </Swiper>
                                </div>
                            </div>
                            <UpAnimatie/>
                        </SwiperSlide>
                    })
                }
                <SwiperSlide>
                    <PhoneMore investmentPtojects={investmentPtojects} visible={visible} show={handleShow}/>
                </SwiperSlide>
            </Swiper>}
            <Drawer close={drawerClose} detail={detail} visible={visible}/>
            </div>
    )
}


export default Phone;