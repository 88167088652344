import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel } from 'swiper';
import 'swiper/css';
import 'swiper/css/mousewheel';
import { useTranslation } from 'react-i18next';


import banStyles from './index.module.scss'

import UpAnimatie from '../../../components/UpAnimatie'

let time=null;

function Banner(props) {
  let swExample = undefined;
  const {t, i18n}=useTranslation();
  const {language}=i18n;
  const [full, setFull] = useState(false)
  const [animationStatus, setAnimationStatus] = useState(0)
  const [isHint,setIsHint]=useState(true)
  const content=t('home.banner.content');
  let startx, starty;
  
  const [terminal,setTerminal]=useState('pc')
  useEffect(() => {
    window.addEventListener('resize',adapter)
    adapter()
    return ()=>{
      window.removeEventListener('resize',adapter)
    }

  }, [])

  const adapter=(e)=>{
    if (/(iPhone|ipad|iPod|ios)/i.test(navigator.userAgent)) {
      setTerminal('phone')
      terminalFun('phone')
    } else if (/(Android)/i.test(navigator.userAgent)) {
      setTerminal('phone')
      terminalFun('phone')
    } else {
      setTerminal('pc')
      terminalFun('pc')
    };
   
  }

  //监听方法
 const terminalFun=(type)=>{
  if (type == 'pc') {
    // 给页面绑定鼠标滚轮事件,针对火狐的非标准事件 
    window.addEventListener("DOMMouseScroll", scrollFunc) // 给页面绑定鼠标滚轮事件，针对Google，mousewheel非标准事件已被弃用，请使用 wheel事件代替
    window.addEventListener("wheel", scrollFunc)   // ie不支持wheel事件，若一定要兼容，可使用mousewheel
    window.addEventListener("mousewheel", scrollFunc)
  } else if (type == 'phone') {
    let appDom = document.querySelector('#app')
    appDom.style.overflow = "hidden"  //初始化进来
    //手指接触屏幕
    document.addEventListener("touchstart", function (e) {
      startx = e.touches[0].pageX;
      starty = e.touches[0].pageY;
    }, false);

    //手指离开屏幕
    document.addEventListener("touchend", function (e) {
      let endx, endy;
      endx = e.changedTouches[0].pageX;
      endy = e.changedTouches[0].pageY;
      let direction = getDirection(startx, starty, endx, endy);
      if (direction == 1 && swExample.$el) {
        swExample.slideNext()
      } else if (direction == 2 && appDom.scrollTop <= 0) {
        appDom.style.overflow = 'hidden'
        if(swExample.$el) swExample.slidePrev()
      }
    }, false);

    document.querySelector('#app').addEventListener('scroll',(e)=>{
      if(e.target.scrollTop>0){
        setIsHint(false)
      }else{
        setIsHint(true)
      }
    },true)
  }
 }

  //pc - 鼠标监听操作
  const scrollFunc = (e) => {
    var e = e || window.event;
    if (e.wheelDelta) {
      if (e.wheelDelta > 0 && document.querySelector('#app').scrollTop <= 5) { //当鼠标滚轮向上滚动时
        // if(document.querySelector('#mark').style&&document.querySelector('#mark').style.display=='block'){
        //   swExample.slidePrev()
        // }
        // document.querySelector('#mark').style.display='none';
        if(swExample) swExample.enable()
      }
    } else if (e.detail) {
      if (e.detail < 0 && document.querySelector('#app').scrollTop <= 5) { //当鼠标滚轮向上滚动时
        // if(document.querySelector('#mark').style&&document.querySelector('#mark').style.display=='block'){
        //   swExample.slidePrev()
        // }
      //  document.querySelector('#mark').style.display='none';
      if(swExample) swExample.enable()
      }
    }
  }

  //phone - 手指滑动判断方向
  const getAngle = (angx, angy) => {
    return Math.atan2(angy, angx) * 180 / Math.PI;
  }

  const getDirection = (startx, starty, endx, endy) => {
    var angx = endx - startx;
    var angy = endy - starty;
    var result = 0;

    //如果滑动距离太短
    if (Math.abs(angx) < 2 && Math.abs(angy) < 2) {
      return result;
    }

    var angle = getAngle(angx, angy);
    if (angle >= -135 && angle <= -45) {
      result = 1;
    } else if (angle > 45 && angle < 135) {
      result = 2;
    } else if ((angle >= 135 && angle <= 180) || (angle >= -180 && angle < -135)) {
      result = 3;
    } else if (angle >= -45 && angle <= 45) {
      result = 4;
    }

    return result;
  }
   const onSwiper=(swiper) => {
    swExample = swiper
  }


  const onSlideChange=(v) => {
    if (v.isEnd) {
      setAnimationStatus(1)
      if (terminal == 'pc') {
        clearTimeout(time); //清除之前的定时器
        time = setTimeout(() => { //初始化现在的定时器    
          if(v.activeIndex == 1){
            // document.querySelector('#mark').style.display='block'
            v.disable()
            clearTimeout(time); //清除之前的定时器
            time=null
          }else{
            clearTimeout(time); //清除之前的定时器
            time=null
          }
            
        }, 1000)
      } else if (terminal == 'phone') {
        setTimeout(() => {
          document.querySelector('#app').style.overflow = "hidden auto"
        }, 150);
      }
    } else {
      setAnimationStatus(2);  //动画开始
    }
  }
       
  return (
    <div className={`${banStyles.banner} banner_wrapper`} style={{ position: 'relative' }}>
      {
        terminal == 'phone' && props.platform == 'phone' ? (
          <div className={`${banStyles.phone_banner} ${animationStatus > 0 ? animationStatus == 1 ? banStyles.benner_animation_left_start: banStyles.benner_animation_left_end: ''} defalut_banner`}></div>
        )
          : (
            <>
              <div className={`${banStyles.pc_banner} ${animationStatus > 0 ? animationStatus == 1  ? banStyles.benner_animation_right_start :banStyles.benner_animation_right_end: ''} defalut_banner`} ></div>
              {/* {
                full && <div style={{ width: '100vw', height: '100vh', position: 'absolute', top: '0', left: '0', zIndex: '2', backgroundColor: 'rgba(0,0,0,0.6)' }}></div>
              } */}
              <div id='mark' style={{display:'none', width: '100vw', height: '100vh', position: 'absolute', top: '0', left: '0', zIndex: '2', backgroundColor: 'rgba(0,0,0,0.5)' }}></div>
            </>
          )
      }
      <Swiper modules={[Mousewheel]} mousewheel={terminal == 'pc'? true : false}
        direction= "vertical"
        onSwiper={onSwiper}
        onSlideChange={onSlideChange}
        noSwiping={true}
        noSwipingClass={'swiper-no-swiping'}
        style={{ width: '100%', height: '100%' }}>
        <SwiperSlide style={{ width: '100%', height: '100%' }} className='swiper-no-swiping'>
          {
            terminal == 'phone' && props.platform == 'phone' ?  (
              <>
                <div className={banStyles.cont}>
                  <div className={banStyles.title_box}>
                    <h5 className={banStyles.title}>未来城市<br />探索者</h5>
                    {/* <p className={banStyles.title_en}>CONNECT WITH <br />THE FUTURE<br /> OF CITY</p> */}
                    <p className={`${banStyles.title_en}`}>THE EXPLORER OF THE FUTURE CITY</p>
                  </div>
                </div>
              </>
            ):(
              <div className={banStyles.cont}>
                <div className={`${banStyles.title_box} ${banStyles.min_pc_title_box}`}>
                  <h5 className={`${banStyles.title}`}>未来城市探索者</h5>
                  <p className={`${banStyles.title_en}`}>THE EXPLORER OF THE FUTURE CITY</p>
                </div>
              </div>
            ) 
          }
        </SwiperSlide>
        <SwiperSlide style={{ width: '100%', height: '100%' }} className='swiper-no-swiping'>
          {
            terminal == 'phone' && props.platform == 'phone' ?  (
              <>
                <div className={`${banStyles.info} ${language != 'cn' ?banStyles.enInfo:''}`}>
                  <p className={banStyles.p}>{content}</p>
                </div>
              </>
            ):(
              <div className={`${banStyles.info} ${language != 'cn' ?banStyles.enInfo:''}`}>
                <p className={`${banStyles.p} ${banStyles.min_pc_p}`}>{content}</p>
              </div>
            ) 
          }
        </SwiperSlide>
      </Swiper>
      {
        terminal == 'phone' && isHint &&  <UpAnimatie />
      }
    </div>
  )
}
export default connect(
  state => ({
    platform: state.platform
  })
)(Banner)