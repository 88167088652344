import React, { useEffect } from 'react'
import Swiper from 'swiper'
import 'swiper/css';
import 'swiper/css/scrollbar';

import pStyle from './phone.module.scss'

export default function PhoneCont(props) {
    const { cont } = props

    useEffect(() => {
        setTimeout(() => {
            var swiper = new Swiper(".mySwiper1", {
                direction: "vertical",
                slidesPerView: "auto",
                freeMode: true,
                // scrollbar: {
                //     el: ".swiper-scrollbar1",
                // },
                mousewheel: true,
            });
        }, 100);
    }, [])

    return (
        <div className="swiper mySwiper1" style={{ height:'25.7vh', width: '100%' }}>
            <div className="swiper-wrapper">
                <div className="swiper-slide" style={{ height: 'auto' }}>
                    <div className={pStyle.phone_content}>
                        {cont}
                    </div>
                </div>
            </div>
        </div>
    )
}
