import axios from 'axios'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  // baseURL: 'https://www.rayvc.cn/prod-api/',
  baseURL: process.env.REACT_APP_BASE_URL,
  // 超时
  timeout: 10000
})

// request拦截器
service.interceptors.request.use(config => {
    return config
}, error => {
    console.log(error)
    Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200;
    if(code == 200){
        return res.data
    }
    
  },
  error => {
    return Promise.reject(error)
  }
)

export default service
