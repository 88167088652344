import React, { useState, useEffect, Fragment , useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { footer } from '../../../redux/actions/footer';    //引用action里面方法返回=>dispatch
import { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

import './index.swiper.css';
import contStyles from './index.module.scss';

import { getHomeDatas,newsList as queryNewsList } from '../../../api/website';

function Content(props) {
    const navigate=useNavigate();
    const [newsList, setNewsList] = useState([]);
    const [introduce, setIntroduceList] = useState([]);
    const [terArr, setTerArr] = useState([]);
    const [terminal,setTerminal]=useState('pc');
    const {t,i18n}=useTranslation();
    const {language}=i18n;
    const swiper1Ref = useRef(null)

    //请求数据
    const getdata = async () => {
        let res = await getHomeDatas();
        //footer存在redux里面
        // if (res.bottomList.length) {
        //     const obj=res.bottomList[0];
        //     const footerObj={
        //         ...obj,
        //         bottomAddress:obj?.[t('footer.bottomAddress')],
        //         bottomContactEmail:obj?.[t('footer.bottomContactEmail')],
        //         bottomEmail:obj?.[t('footer.bottomEmail')],
        //         bottomInfo:obj?.[t('footer.bottomInfo')]
        //     }
        //     props.footer(footerObj);
        // }

        //关于
        if (res.introduceList.length) {
            const introduceList=res.introduceList.map(el=>{
                return {
                    ...el,
                    achievementTitle:el[t('home.introduceList.achievementTitle')],
                    introduceTitle:el[t('home.introduceList.introduceTitle')],
                    introduceContent:el[t('home.introduceList.introduceContent')],
                }
            });
            console.log(introduceList)
            setIntroduceList(introduceList);
        }

        //领域
        if (res.fieldList.length) {
            let img_url = process.env.REACT_APP_IMG_URL
            let arr = res.fieldList.map(el => {
                return {
                    label: el.fieldTitle,
                    icon: img_url + el.fieldIcon,
                    bg: img_url + el.fieldImgUrl

                }
            })
            setTerArr(arr)
        }
        //新闻
        // if (res.dynamicList.length) {
        //     setNewsList(res.dynamicList)
        // }
    }
    //主页新闻部分
    const getNewsList = async () => {
        try{
            const res=await queryNewsList({homepageUpdates:"Y",pageNum: 1,pageSize: 20});
             if (res.rows) {
                setNewsList(res.rows);
            }

        }catch(error){
            console.log(error);
        }
    }

    const handleDetails = (row) => {
        if(row.newsType == 'N'){
            window.open(row.newsUrl || '/');
        }else{
            //state 传参
            navigate(`/newsDetails/${language}?active=news&rayvcNewsId=${row.rayvcNewsId}`)    //后续国际化  使用这一段
            // navigate(`/newsDetails`, { state: { row, nav: '/news', } })
        }
    }

    const handleToPerformance = (index)=>{
        navigate(`/performance/${language}?num=${index}`)
    }

    //dimount
    useEffect(() => {
        getdata();
        getNewsList();
        window.addEventListener('resize',adapter)
        adapter()
        return ()=>{
        window.removeEventListener('resize',adapter)
        }
    }, [])

    const adapter=(e)=>{
        if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        //   console.log('iPhone')
          setTerminal('phone')
        } else if (/(Android)/i.test(navigator.userAgent)) {
        //   console.log('Android')
          setTerminal('phone')
        } else {
        //   console.log('pc')
          setTerminal('pc')
        };
    }

    //swiper 默认设置
    const swiper_stting = {
        autoplay: {
            delay: 2000,
            stopOnLastSlide: false,
            disableOnInteraction: true,
        },
        pagination: {
            clickable: true,
            clickableClass: 'pagination_modifer',
            bulletClass: 'pagination_style',
            //激活样式
            bulletActiveClass: 'pagination_active',
        },
        modules: []
    }

    //业绩
    const listDom = () => {
        let  btn_text_obj={
            "1":t('home.introduceBtns.lot'),
            "2":t('home.introduceBtns.clean'),
            "3":t('home.introduceBtns.humanity'),
        }
        return props.platform == 'phone' ?
            (
                introduce.map((el, index) => {
                    return <Fragment key={index}>
                        <div className={contStyles.img_box} style={{ backgroundImage: `url(${process.env.REACT_APP_IMG_URL + el.introduceImgUrl})`,marginLeft:index % 2 == 0 ? '' :'.5333rem'}}>
                            <h5 className={terminal == 'phone' ? contStyles.phone_h5 : `${contStyles.phone_h5} ${contStyles.min_pc_phone_h5}`} style={{left:index % 2 == 0? '0.5333rem':'0px'}}>{el.introduceTitle}</h5>
                        </div>
                        <div className={terminal == 'phone' ? contStyles.right_box : `${contStyles.right_box} ${contStyles.min_pc_right_box}`}>
                            <div className={contStyles.info_box}>
                                <h5 className={terminal == 'phone' ? contStyles.h5 : `${contStyles.h5} ${contStyles.min_pc_h5}`}>{el.achievementTitle}</h5>
                                <p className={contStyles.info} style={{maxHeight:'auto'}}>{el.introduceContent}</p>
                                <button type="button" className={contStyles.btn} onClick={()=>handleToPerformance(index)}>{btn_text_obj[el.introduceId]}<img className={contStyles.arrows} src={require('@/assets/images/arrows.png')} width="15"/></button>
                            </div>
                        </div>
                    </Fragment>
                })
            )
            :
            (
                introduce.map((el, index) => {
                    return <Fragment key={index}>
                        {
                            index % 2 == 0 ? <>
                                <div className={contStyles.img_box} style={{ backgroundImage: `url(${process.env.REACT_APP_IMG_URL + el.introduceImgUrl})` }}></div>
                                <div className={contStyles.right_box}>
                                    <div className={contStyles.info_box}>
                                        <h5 className={contStyles.h5}>{el.introduceTitle}<br />{el.achievementTitle}</h5>
                                        <p className={contStyles.info}>{el.introduceContent}</p>
                                        <button type="button" className={contStyles.btn} onClick={()=>handleToPerformance(index)}>{btn_text_obj[el.introduceId]}<img className={contStyles.arrows} src={require('@/assets/images/arrows.png')} width="15"/></button>
                                    </div>
                                </div>
                            </>
                                : <>
                                    <div className={contStyles.right_box}>
                                        <div className={contStyles.info_box}>
                                            <h5 className={contStyles.h5}>{el.introduceTitle}<br />{el.achievementTitle}</h5>
                                            <p className={contStyles.info}>{el.introduceContent}</p>
                                            <button type="button" className={contStyles.btn} onClick={()=>handleToPerformance(index)}>{btn_text_obj[el.introduceId]}<img className={contStyles.arrows} src={require('@/assets/images/arrows.png')} width="15"/></button>
                                        </div>
                                    </div>
                                    <div className={contStyles.img_box} style={{ backgroundImage: `url(${process.env.REACT_APP_IMG_URL + el.introduceImgUrl})` }}></div>
                                </>
                        }
                    </Fragment>
                })
            )
    }
    //swiper里面内容是配
    const swiper_con_dom = (n_item) => {
        return props.platform == 'phone' ?
            (
                <div className={contStyles.new_cont_box} onClick={()=>handleDetails(n_item)}>
                    <div className={contStyles.new_cont}>
                        <h5 className={contStyles.new_h5}>{n_item.rayvcNewsTitle}</h5>
                    </div>
                    <div className={contStyles.news_img}>
                        <img src={n_item.rayvcNewsImgUrl ? process.env.REACT_APP_IMG_URL + n_item.rayvcNewsImgUrl : require('../../../assets/images/news_img.jpg')} width="100%" alt="news" />
                    </div>
                    <div className={contStyles.new_p}>
                        {n_item.rayvcNewsIntroduce}
                    </div>
                </div>
            )
            :
            (
                <div className={contStyles.new_cont_box} onClick={()=>handleDetails(n_item)}>
                    <div className={contStyles.new_cont}>
                        <h5 className={contStyles.new_h5}>{n_item.rayvcNewsTitle}</h5>
                        <p className={contStyles.new_p}>{n_item.rayvcNewsIntroduce}</p>
                    </div>
                    <div className={contStyles.new_img_box} style={{cursor:'pointer'}}>
                        <img className={contStyles.new_img} src={n_item.rayvcNewsImgUrl ? process.env.REACT_APP_IMG_URL + n_item.rayvcNewsImgUrl : require('../../../assets/images/news_img.jpg')} width="100%" alt="news" />
                    </div>
                </div>
            )
    }

    return (
        <div className={contStyles.wrapper}>
            <div className={contStyles.Performance_box}>
                {listDom()}
            </div>
            <div className={contStyles.news_wrapper}>
                <div className={contStyles.news_cont}>
                    <div className={contStyles.news_swiper_box}>
                        <h6 className={contStyles.h6}>最新动态</h6>
                        { newsList && newsList.length ? <Swiper
                            modules={[Pagination, Autoplay]}
                            style={{ width: '100%', height: '100%' }}
                            autoplay={swiper_stting.autoplay}
                            pagination={swiper_stting.pagination}
                            id='swiper-container1' 
                            ref={swiper1Ref}
                            onSwiper={()=>{
                                if(swiper1Ref.current){
                                    swiper1Ref.current.onmouseover = () => {
                                        swiper1Ref?.current?.swiper?.autoplay?.stop() //鼠标移入停止轮播
                                    };
                                    swiper1Ref.current.onmouseout = () => {
                                        swiper1Ref?.current?.swiper?.autoplay?.start() //鼠标移出开始轮播
                                    }
                                }
                            }}
                        >
                            {
                                newsList.map((n_item, n_index) => {
                                    return (
                                        <SwiperSlide key={n_index} style={{ width: '100%', height: '100%', fontSize: '14px', overflow: 'hidden' }}>
                                            {
                                                swiper_con_dom(n_item)
                                            }
                                        </SwiperSlide>
                                    )
                                })
                            }
                        </Swiper>:<></>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default connect(
    state => ({
        platform: state.platform
    }),
    { footer }
)(Content)