import React, { useEffect } from 'react'
import dStyle from './index.module.scss'

export default function Dialog(props) {
  
  const data=props.option

  useEffect(() => {
    document.body.style.overflow = 'hidden'
  }, [])

  const close = (e) => {
    e.stopPropagation()
    props.close(false)
    document.body.removeAttribute('style')
  }


  return (
    <div className={dStyle.wrapper} onClick={close}>
      <div className={dStyle.content}>
        <div className={dStyle.close_btn}>
          <div className={dStyle.btn} onClick={close}>×</div>
        </div>
        <div className={dStyle.cont_text}>
          <h5 className={dStyle.h5}>{data.noticeTitle}<br/>{data.noticeTitleTwo}</h5>
          <div className={dStyle.info_box}>
            <p className={dStyle.info}>
              {data.noticeContentTwo}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
