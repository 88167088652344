import React, { useEffect, useState, useTransition } from "react";
import { Route, Routes, Navigate, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import i18n from './i18n'
// import { TransitionGroup, CSSTransition } from 'react-transition-group'

import { KeepAlive } from 'react-activation';
import Transition from "./components/Transition";
import Home from "./constatns/Home";
import Performance from './constatns/Performance'
import News from './constatns/News'
import NewsDetails from './constatns/NewsDetails'
import Team from './constatns/Team'

import Navbar from "./layout/Navbar";
// import Footer from "./layout/Footer";

import 'animate.css'

import './App.css'

const App = (props) => {
  const location = useLocation();

  useEffect(() => {
    let routes = location.pathname.split('/').filter(d => d);
    let nowLang = routes[routes.length - 1];
    i18n.changeLanguage(nowLang)
  }, [])

  return (
    <div id="app">
      {/* 头部 */}
      <Navbar />
      <Routes>
        {/* 后续国际化 加上动态路由参数 */}
        <Route path="/home/:language" element={
          <Transition cont={<Home />} pathname={location.pathname}></Transition>
        }></Route>
        <Route path="/performance/:language" element={
          <KeepAlive cacheKey="performance_key"><Transition cont={<Performance />} pathname={location.pathname}></Transition></KeepAlive>
        }></Route>
        <Route path="/news/:language" element={
            <KeepAlive cacheKey="news_key" when={props.alive}><Transition cont={<News />} pathname={location.pathname}></Transition></KeepAlive>
       }></Route>
        <Route path="/newsDetails/:language" element={
          <Transition cont={<NewsDetails />} pathname={location.pathname}></Transition>
        }></Route>
        <Route path="/team/:language" element={
          <KeepAlive cacheKey="team_key"><Transition cont={<Team />} pathname={location.pathname}></Transition></KeepAlive>
        }></Route>
        {/* 重定向 */}
        <Route path="/" element={<Navigate to={`/home/${i18n.language}`} />}></Route>
        {/* <Route path="/" element={<Navigate to={`/home/cn`} replace={true} />}></Route> */}
      </Routes>
      {/* 尾部 */}
      {/* <Footer /> */}
    </div>
  )
}


export default connect(
  state => ({
    count: state.count,
    alive: state.alive
  })
)(App);
