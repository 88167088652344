import React from 'react'
import { connect } from 'react-redux'

import Pc from './pc'
import Phone from './phone/phone'

function Performance(props) {

    return (
        <>
            {
                props.platform == 'phone' ? <Phone/> : <Pc/>
            }
        </>
    )
}


export default connect(
    state => ({
        platform: state.platform
    })
)(Performance)