import { SET_ALIVE} from '../constant'
//
const alive=true
export default function aliveReducers(preplatform=alive,action){
    const {type,data}=action
    switch (type){
        case SET_ALIVE:
            return data
        default :
            return preplatform
    }
} 