import React, { useEffect, useState } from 'react'
import { useNavigate , useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component';
import { connect } from 'react-redux'
import newStyle from './index.module.scss'

import {newsList} from '@/api/website'

import Foolter from '@/layout/Footer'

let oldLang=window.location.hash.split('/')[window.location.hash.split('/').length - 1]

function News(props) {
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  //路由
  const navigate = useNavigate()

  const handleDetails = (row) => {
    if(row.newsType == 'N'){
      window.open(row.newsUrl);
    }else{
      //state 传参
      navigate(`/newsDetails/${language}?active=news&rayvcNewsId=${row.rayvcNewsId}`)    //后续国际化  使用这一段
      // navigate(`/newsDetails`, { state: { row, nav: '/news', } })
    }
  }
  const [list, setList] = useState([]);
  const [hasMore, setHasMore] = useState(true)
  const [pageNum,setPageNum]=useState(0)
  const [loading,setLoading]=useState(false)
  const requestList = async () => {
    let newPageNum=pageNum
    newPageNum++
    setPageNum(newPageNum)
    const res=await newsList({pageNum:newPageNum,pageSize:6})
    if(res.code == 200){
      // 只有一页
      if(res.total <= 6 ){
        setHasMore(false)
        let newList=list.concat(res.rows.map(el=>{
          return {
            ...el,
            rayvcNewsTitle:el?.[t('news.rayvcNewsTitle')] || '',
            rayvcNewsIntroduce:el?.[t('news.rayvcNewsIntroduce')] || '',
            createTime:monent(el.createTime)}
        }))
        setList(newList)
      }else{
        let rowList=res.rows;
        const oldList=list.map(el=>({...el}))
        let newList=[].concat(oldList,rowList)
        //最后一页
        if(list.length + rowList.length >= res.total){
          setHasMore(false)
          setList(newList)
        }else{
          setList(newList)
        }
      }
    }
  };
  
  useEffect(() => {
    requestList()
    if (props.platform == 'phone') document.querySelector('#app').style.overflow = "hidden auto"
  }, [])

  useEffect(()=>{ //解决-侧边出现两个滚动条
    if(oldLang != language){
      setLoading(true)
      setTimeout(() => {
        setLoading(false)
        oldLang=language;
      }, 500);
    }
  },[language])
  


  let listItem = list.map((el, index) => {
    return props.platform == 'phone' ?
      (
        <div className={newStyle.list_item} key={index} onClick={() => handleDetails(el)}>
          <div className={newStyle.img_box}>
            <img src={process.env.REACT_APP_IMG_URL + el.rayvcNewsImgUrl} width="100%" />
          </div>
          <div className={newStyle.phone_info}>
            <h5 className={newStyle.phone_h5}>{el.rayvcNewsTitle}</h5>
            <span className={newStyle.phone_date}>{el.createTime}</span>
          </div>
        </div>
      )
      :
      (
        <div className={newStyle.list_item} key={index} onClick={() => handleDetails(el)}>
          <div className={newStyle.left}>
            <span className={newStyle.date}>{el.createTime}</span>
            <h5 className={newStyle.h5}>{el.rayvcNewsTitle}</h5>
            <div className={newStyle.text}>{el.rayvcNewsIntroduce}</div>
          </div>
          <div className={newStyle.right}>
            <img src={process.env.REACT_APP_IMG_URL + el.rayvcNewsImgUrl} width="100%" />
          </div>
        </div>
      )
  })

  return (
    <>
     <div id='scrollableDiv' style={{ width: '100%', height: '100vh', overflowY: loading ?'hidden' :'auto' }}>
     <div className={newStyle.wrapper}>
       <div className={newStyle.banner_box}>
         <div className={newStyle.banner_cont}>
           <h5 className={newStyle.title}>新闻中心</h5>
           <h5 className={newStyle.title_en}>NEWS CENTER</h5>
         </div>
       </div>
       <div className={newStyle.list_box}>
         <InfiniteScroll
           scrollableTarget="scrollableDiv"
           dataLength={list.length}
           next={requestList}
           hasMore={hasMore}
           endMessage={
             <p style={{ textAlign: 'center',color:'#999',fontSize:'14px',lineHeight:'40px' }}>
               {/* <b>没有更多了</b> */}
             </p>
           }
           loader={<div style={{ textAlign: 'center', padding: '10px 0', fontSize: '18px', color: '#888' }}>
               <img className={newStyle.loading_icon} src={require('../../assets/images/icon_loading.png')} width="24"/>

           </div>}
         >
           <div>
             {listItem}
           </div>
         </InfiniteScroll>
       </div>
     </div>
     <Foolter />
      </div> 
    </>
  )
}

export default connect(
  state => ({
    platform: state.platform
  })
)(News);


//monent
function monent(dateTime){
  const date=new Date(dateTime);
  const year=date.getFullYear();
  const month=date.getMonth() + 1;
  const day=date.getDate();
  return `${year}-${month < 10 ? '0'+month : month}-${day < 10 ? '0'+day : day}`
}