import { SET_DIALOG} from '../constant'
//
const visible=sessionStorage.getItem('inform') ? sessionStorage.getItem('inform') : 0
export default function platDialogReducers(preplatform=visible,action){
    const {type,data}=action
    switch (type){
        case SET_DIALOG:
            sessionStorage.setItem('inform',data)
            return data
        default :
            return preplatform
    }
} 