import React,{useState,useEffect} from 'react'
import {connect} from 'react-redux'
import { useTranslation } from 'react-i18next'
import ftStyles from './index.module.scss'

import { footer } from '@/redux/actions/footer'
import { getHomeDatas } from '@/api/website'

export default connect(
  state=>({
    footerData:state.footer
  }),
  {footer}
)
(function Footer(props) {
  const {t,i18n}=useTranslation();
  const {language}=i18n;
  const {bottomAddress,bottomContactEmail,bottomEmail,bottomInfo,qrCodeUrl}=props.footerData
  const info_arr=[
      {
        title:t('footer.address'),
        text:bottomAddress,
        className:ftStyles.address
      },
      {
        title:t('footer.email'),
        text:bottomContactEmail,
        className:ftStyles.email
      },
      {
        title:t('footer.bp'),
        text:bottomEmail,
        className:ftStyles.bp
      },

  ]
  console.log(info_arr)
  const [terminal,setTerminal]=useState('pc');
  useEffect(()=>{
        getdata();
        window.addEventListener('resize',adapter)
        adapter()
        return ()=>{
        window.removeEventListener('resize',adapter)
        }
    }, [])

    const adapter=(e)=>{
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        // console.log('iPhone')
        setTerminal('phone')
      } else if (/(Android)/i.test(navigator.userAgent)) {
        // console.log('Android')
        setTerminal('phone')
      } else {
        // console.log('pc')
        setTerminal('pc')
      };
    }

    //请求数据
    const getdata = async () => {
      let res = await getHomeDatas();
      //footer存在redux里面
      if (res.bottomList.length) {
          const obj=res.bottomList[0];
          const footerObj={
              ...obj,
              bottomAddress:obj?.[t('footer.bottomAddress')],
              bottomContactEmail:obj?.[t('footer.bottomContactEmail')],
              bottomEmail:obj?.[t('footer.bottomEmail')],
              bottomInfo:obj?.[t('footer.bottomInfo')]
          }
          props.footer(footerObj);
      }
    }
  return (
    <>
    <div className={ftStyles.foolter_wrapper}>
      <div className={ftStyles.foolter_cont}>
          <div className={ftStyles.cont_info}>
            {
              info_arr.map((el,index)=>{
                return (
                  <div key={index} className={`${ftStyles.box} ${el.className} ${terminal == 'pc' ? ftStyles.min_pc_box:''}`}>
                      <h5 className={ftStyles.h5}>
                        {el.title}
                      </h5>
                      <p className={ftStyles.p}>
                        {el.text}
                      </p>
                  </div>
                )
              })
            }
              
          </div>
          <div className={ftStyles.qr_box}>
              <img className={ftStyles.qr} src={qrCodeUrl? process.env.REACT_APP_IMG_URL + qrCodeUrl :require('../../assets/images/qr.png')} alt="qr" />
              <p className={ftStyles.qr_p}>{t('footer.prompt')}</p>
          </div>
      </div>
    </div>
    <div className={ftStyles.copyright}>
      {
        <div dangerouslySetInnerHTML={{__html:bottomInfo}}></div>
      }
      </div>
    </>
  )
})
