import store from '../redux/store'
import {platform} from '../redux/actions/platform'

function adapter() {
    const wind_w=window.innerWidth  //浏览器宽度、包含滚动条 
    const dip = document.documentElement.clientWidth
    const rootFontSize = dip / 10
    //600px -- phone
    if(wind_w <= 600){
      store.dispatch(platform('phone'))
    }else{
      store.dispatch(platform('pc'))
    }
    document.documentElement.style.fontSize = rootFontSize + 'px'
  }
  adapter()

  // window.onresize = adapter
  window.addEventListener('resize',adapter,true)